@import "../../node_modules/bulma/css/bulma.min.css";
@import "../../node_modules/@creativebulma/bulma-collapsible/dist/css/bulma-collapsible.min.css";
@import '../../node_modules/bulma-timeline/dist/css/bulma-timeline.min.css';

[data-prefix="fas"] {
    width: auto !important;
    height: 1rem !important;
}

.panel:not(:last-child) {
    margin-bottom: 0 !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell {
    padding: 0 5px !important;
}

html {
    overflow: auto;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}